import {Injectable} from '@angular/core';
import 'rxjs/add/operator/toPromise';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import * as firebase from 'firebase';
import {Observable, of, Subject, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {User} from './User/user';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class AuthService {
  private _authenticated = false;
  private userInfoSource = new Subject<string>();
  userInfo$ = this.userInfoSource.asObservable();
  public user: User;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';


  setUserInfo(info: string) {
    this.userInfoSource.next(info);
  }

  constructor(
    public afAuth: AngularFireAuth,
    private httpClient: HttpClient,
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive,
  ) {

    afAuth.authState.subscribe((authState) => {
      if (authState) {
        this._authenticated = true;
      } else {
        this._authenticated = false;
        router.navigate(['/login']);
      }
    });


    idle.setIdle(600);
    idle.setTimeout(1200);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.router.navigate(['/login/'], { queryParams: { reason: 'inactivity' } });
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());
  }

  get isAuthenticated(): boolean {
    return this._authenticated;
  }

  doLogin(params) {
    const self = this;
    this._authenticated = false;
    return new Promise<any>((resolve, reject) => {
        localStorage.removeItem('User');
        this.afAuth.auth.signInWithEmailAndPassword(params.email, params.password)
          .then(res => {
            this._authenticated = true;
            self.setUserInfo(this.afAuth.auth.currentUser.displayName);
          }).then(res => {
          this.user = new User({
            idUser: -1,
            Handle: '',
            Email: this.afAuth.auth.currentUser.email,
            Verified: false,
            Password: '',
            RequiredAction: -1,
            Token: '',
            Message: '',
            FirebaseUid: this.afAuth.auth.currentUser.uid
          });

          this.httpClient.put<User>(environment.locateApiEndpoint + 'logon',
            {
              ticket: '',
              intention: 'logon',
              params: this.user
            }).subscribe(function (val) {
            localStorage.setItem('User', JSON.stringify(val));
            console.log(val);
            self.reset();
            resolve();
          }, locateServiceError => (reject(locateServiceError)));
        }).catch((firebaseError) => {
          reject(firebaseError);
        });
      }
    );``
  }

  doLogout() {
    return new Promise<any>((resolve, reject) => {
      if (this.afAuth.auth.currentUser) {
        this.afAuth.auth.signOut()
          .then(res => {
              this._authenticated = false;
              this.router.navigate(['/login']);
              resolve(res);
            }
          );
      } else {
        this._authenticated = false;
        reject();
      }
    });
  }

  private doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth.createUserWithEmailAndPassword(value.email, value.password)
        .then(res => {
          resolve(res);
        }, err => reject(err));
    });
  }

  getUserName(): string {
    if (this.isAuthenticated) {
      return (this.afAuth.auth.currentUser.displayName !== null && this.afAuth.auth.currentUser.displayName.length > 0) ?
        this.afAuth.auth.currentUser.displayName : this.afAuth.auth.currentUser.email.split('@')[0];
    }
    return 'Not Logged In';
  }

  public resetPassword(value): Promise<boolean> {
    const self = this;
    return new Promise(function (resolve, reject) {
      const credential = firebase.auth.EmailAuthProvider.credential(value.email, value.currentPassword);
      self.afAuth.auth.currentUser.reauthenticateWithCredential(credential).then(function reauthenticate() {
      }).catch((error) => {
          reject(error);
        }
      ).then(function () {
        self.afAuth.auth.currentUser.updatePassword(value.newPassword).catch((error) => {
          reject(error);
        }).then(function () {
          resolve(true);
        }).catch((error) => {
          reject(error);
        });
      });
    });
  }

  public updateProfile(value): Promise<boolean> {
    const self = this;
    return new Promise(function (resolve, reject) {
      const credential = firebase.auth.EmailAuthProvider.credential(self.afAuth.auth.currentUser.email, value.currentPassword);
      self.afAuth.auth.currentUser.reauthenticateWithCredential(credential).then(function reauthenticate() {
      }).catch((error) => {
        reject(error);
      }).then(function () {
        self.afAuth.auth.currentUser.updateProfile({
          displayName: value.displayName
        }).catch((error) => {
          reject(error);
        }).then(function () {
          self.afAuth.auth.currentUser.updateEmail(value.email).catch(() => {
          }).then(function () {

            self.setUserInfo(self.afAuth.auth.currentUser.displayName === '' ?
              self.afAuth.auth.currentUser.email : self.afAuth.auth.currentUser.displayName);
            resolve(true);
          }).catch(function (error) {
            reject(error);
          });
        });
      });
    });
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
