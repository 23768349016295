import {Injectable} from '@angular/core';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {environment} from '../../../environments/environment';
import {Order} from '../order/order';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocateStreamService {
  public order$: Observable<Order[]>;
  private fdb: AngularFireDatabase;

  constructor(fdb: AngularFireDatabase) {
    this.fdb = fdb;
  }

  // public subscribeManager() {
  //   this.order$ = this.fdb.list('orders')
  //     .valueChanges()
  //     .pipe(
  //       map(changes => {
  //           return changes.map(rawdata => {
  //             return new Order(rawdata);
  //           }).filter(item => ['APEX', '2GDN', '1GDN', 'ELLS', 'STER', 'GRST'].indexOf(item.MPID) > -1);
  //         }
  //       )
  //     );
  // }


  public subscribeManager() {
    this.order$ = this.fdb.list('orders', ref =>
      ref.orderByChild('idEntity').equalTo(2))
      .valueChanges()
      .pipe(
        map(changes => {
            return changes.map(rawdata => {
              return new Order(rawdata);
            });
          }
        )
      );
  }


  public subscribeTraderID(traderId: string) {
    this.order$ = this.fdb.list('orders',
      ref =>
        ref.orderByChild('Trader').equalTo(traderId))
      .valueChanges()
      .pipe(
        map(changes => {
            return changes.map(rawdata => {
              return new Order(rawdata);
            });
          }
        )
      );
  }
}


/*
https://github.com/angular/angularfire2/blob/master/docs/firestore/querying-collections.md
.query(ref => ref.where('size', '==', 'large'))

{
query: {
  equalTo: this.route.params.map((params: Params) => params['weekid'])
}
*/
