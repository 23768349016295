import 'ag-grid-community';
import {Component, Inject, Injectable, OnDestroy, OnInit} from '@angular/core';
import {LocateStreamService} from '../stream/locate-stream.service';
import {GridOptions} from 'ag-grid-community';
import {CellFormatter} from '../../shared/grid/cell-formatter';
import {Subscription} from 'rxjs';
import {Utils} from '../../shared/utils';
import {User} from '../../authentication/User/user';
import {AuthService} from '../../authentication/auth.service';

@Component({
  selector: 'app-locategrid',
  templateUrl: './locategrid.component.html',
  styleUrls: ['./locategrid.component.scss']

})

export class LocategridComponent implements OnInit, OnDestroy {

  constructor(private locateStreamService: LocateStreamService) {
    this.currentUser = new User(JSON.parse(localStorage.getItem('User')));
    this.isManager = this.currentUser.isManager();
    this.defineColumns();
    this.defineGridOptions();
  }
  public gridApi;
  private gridColumnApi;
  public gridOptions: GridOptions;
  private _sub: Subscription;
  public hasRowsSelected = false;
  public currentUser: User;
  public isManager;

  static cellstyle(params) {
    switch (params.data.statusStyle) {
      case 'status-fill':
        return {'color': 'white', 'background-color': '#689f38'};
      case 'status-open':
        return {'color': 'black', 'background-color': '#F57C00'};
      case 'status-unprocessed':
        return {'color': 'white'};
      case 'status-pend':
        return {'color': 'black', 'background-color': '#c5e1a5'};
      case 'status-cancel':
        return {'color': 'black', 'background-color': '#959595'};
      case 'status-rejected':
        return {'color': '#b0b1ae', 'background-color': '#344428'};
      case 'status-overnight':
        return {'color': '#e1ffb1', 'background-color': '#33691'};
    }
  }

  getContextMenuItems(params) {
    const result = [
      'copy',
      'excelExport'
    ];
    return result;
  }

  // Grid Events
  gridOnReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.gridApi) {
      this._sub = this.locateStreamService.order$.subscribe(data => {
        this.gridApi.setRowData(data);
      });
      this.gridApi.sizeColumnsToFit();
    }
  }

  gridSizeChanged(params) {
    this.gridApi.sizeColumnsToFit();
    // Hack to hide horizontal scroll bar
    const colState = this.gridColumnApi.getColumnState();
    this.gridColumnApi.setColumnWidth(colState[colState.length - 1].colId, colState[colState.length - 1].width - 20);
  }

  public onExportButton() {
    const exportFileName = Utils.createFileName('vcl-', new Date(), '', '');
    const params = {
      fileName: exportFileName
    };
    this.gridApi.exportDataAsExcel(params);
  }

  public SetFilter(value: string) {
    let valueArray = [];
    if (value === '') {
      valueArray = [''];
    } else {
      valueArray = [value];
    }
    const ordTypeFilled = this.gridApi.getFilterInstance('Status');
    ordTypeFilled.setModel({
      type: 'set',
      values: valueArray,
      newRowsAction: 'keep'
    });
    this.gridApi.onFilterChanged();
  }

  public getSelectedRows(): any {
    return this.gridApi.getSelectedRows();
  }

  public applyManagerSettings() {
  }

  gridOnCellMouseOut(event) {
  }

  gridOnCellMouseOver(event) {
  }

  gridOnRowSelected(event): boolean {
    this.hasRowsSelected = this.gridApi.getSelectedRows().length > 0;
    return this.hasRowsSelected;
  }

  // component events
  ngOnInit() {
  }

  ngOnDestroy() {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }

  private defineGridOptions() {
    const columns: any = this.defineColumns();
    this.gridOptions = {};
    this.gridOptions.defaultColDef = this.defineDefaultColumns();
    this.gridOptions.columnDefs = columns;
    this.gridOptions.floatingFilter = true;
    this.gridOptions.groupIncludeTotalFooter = false;
    this.gridOptions.rowSelection = 'multiple';

    // function assignments
    this.gridOptions.getRowNodeId = (params) => this.getRowNodeId(params);
   // this.gridOptions.getRowClass = (params) => CellFormatter.statusStyle(params);
    this.gridOptions.onGridReady = (event) => this.gridOnReady(event);
    this.gridOptions.getContextMenuItems = (event) => this.getContextMenuItems(event);
    this.gridOptions.onCellMouseOver = (event) => this.gridOnCellMouseOver(event);
    this.gridOptions.onCellMouseOut = (event) => this.gridOnCellMouseOut(event);
    this.gridOptions.onGridSizeChanged = (event) => this.gridSizeChanged(event);
    this.gridOptions.isRowSelectable = function (rowData) {
      return rowData.data ? rowData.data.OrderStatus === 5 || rowData.data.OrderStatus === 0 : false;
    };
    this.gridOptions.onRowSelected = (event) => this.gridOnRowSelected(event);
  }

  private getRowNodeId(data) {
    return data.idOrder;
  }

  private defineColumns() {
    return [
      {
        minWidth: 40, maxWidth: 40, field: 'checkbox', pinned: true,
        checkboxSelection: true, headerCheckboxSelection: true, hide: this.isManager
      },
      {
        headerName: 'Time', minWidth: 90, maxWidth: 90, field: 'time', pinned: true,
        valueFormatter: CellFormatter.timeHMS, sort: 'desc'
      },
      {headerName: 'Symbol', minWidth: 110, maxWidth: 110, field: 'Symbol', pinned: true, filter: 'agTextColumnFilter'},
      {headerName: 'Trader', minWidth: 120, maxWidth: 120, field: 'Trader', pinned: true, filter: 'agTextColumnFilter'},
      {
        headerName: 'MPID', field: 'MPID', minWidth: 90,
        filterParams: {newRowsAction: 'keep'},
        maxWidth: 90, headerTooltip: 'MPID', filter: 'agSetColumnFilter', suppressMenu: false, menuTabs: ['filterMenuTab'],
      },
      {
        headerName: 'OrdQty', minWidth: 75, maxWidth: 75, field: 'OrdQty',
        valueFormatter: CellFormatter.num, type: 'numericColumn'
      },
      {
        headerName: 'OrdPx', minWidth: 75, maxWidth: 75, field: 'OrdPx',
        valueFormatter: CellFormatter.rate, type: 'numericColumn'
      },
      {headerName: 'OrdTime', minWidth: 90, maxWidth: 90, field: 'OrdTime', hide: true},
      {
        headerName: 'OfferQty', minWidth: 75, maxWidth: 75, field: 'OfferQty',
        valueFormatter: CellFormatter.num, type: 'numericColumn'
      },
      {
        headerName: 'OfferPx', minWidth: 75, maxWidth: 75, field: 'OfferPx',
        valueFormatter: CellFormatter.rate, type: 'numericColumn'
      },
      {
        headerName: 'ExecQty', minWidth: 75, maxWidth: 75, field: 'ExecQty',
        valueFormatter: CellFormatter.num, type: 'numericColumn'
      },
      {
        headerName: 'ExecPx', minWidth: 75, maxWidth: 75, field: 'ExecPx',
        valueFormatter: CellFormatter.rate, type: 'numericColumn'
      },
      {headerName: 'ExecTime', minWidth: 90, maxWidth: 90, field: 'ExecTime', hide: true},
      {
        headerName: 'LocateId', minWidth: 80, maxWidth: 80, field: 'idOrder',
        valueFormatter: CellFormatter.orderId, type: 'numericColumn', filter: 'agTextColumnFilter',
      },
      {headerName: 'idSource', minWidth: 100, field: 'idSource', hide: true},
      {headerName: 'idReason', minWidth: 100, field: 'idReason', hide: true},
      {
        headerName: 'Status',
        minWidth: 100,
        colId: 'Status',
        maxWidth: 100,
        filter: 'agSetColumnFilter',
        // field: 'status',
        field: 'locateStatusText',
        filterParams: {newRowsAction: 'keep'},
        cellStyle: LocategridComponent.cellstyle,
        //valueGetter: CellFormatter.statusColumnStyle,
        suppressMenu: false,
        menuTabs: ['filterMenuTab']
      },
      {headerName: 'MktOrdQty', minWidth: 100, maxWidth: 100, field: 'MktOrdQty', hide: true},
      {headerName: 'MktExecQty', minWidth: 100, maxWidth: 100, field: 'MktExecQty', hide: true},
      {
        headerName: 'ExpiryTime', minWidth: 100, maxWidth: 100, field: 'ExpiryTime', hide: true,
        valueFormatter: CellFormatter.timeHMS
      },
      {
        headerName: 'MktStatus', minWidth: 100, field: 'MktStatus', hide: true, valueGetter: CellFormatter.mktStatusColumnStyle,
        filterParams: {newRowsAction: 'keep'},
        maxWidth: 100, headerTooltip: 'MktStatus', filter: 'agSetColumnFilter', suppressMenu: false, menuTabs: ['filterMenuTab']
      },
      {
        minWidth: 40
      }
    ];
  }

  private defineDefaultColumns() {
    return {
      enableFilter: true,
      enableCellChangeFlash: true,
      sortable: true,
      editable: false,
      suppressMenu: true,
      floatingFilter: true,
      resizable: true,

      // cellStyle: PositionsComponent.styleZero,
      floatingFilterComponentParams: {
        suppressFilterButton: true
      }
    };
  }
}
